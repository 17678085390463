import router from './router'
import { setDocumentTitle } from '@/utils/domUtil'
import NProgress from 'nprogress'
import { post } from '@/utils/http'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

/**
 * no redirect whitelist
 * scanSupplierResult：扫描结果
 */
let flag=false
router.beforeEach((to, from, next) => {
    NProgress.start(); // start progress bar
    // console.log(from)
    // console.log(to)
    // console.log(from.name, to.name)

    to.meta && (typeof to.meta.title !== 'undefined' && setDocumentTitle(`${to.meta.title} `));


    if (['home', 'home1', 'home2', 'home3'].includes(to.name)&&!flag) {
        console.log("1----------");
        // 缓存，10秒内，不需要再重复调用
        // next()
        post(`/api/public/v2/manufacture/tracing/${to.query.traceCode}/findUsedTraceTemplates`, {}, false).then((res) => {
            
            if (from.name === 'home' + res.data.sort) {
                console.log("3----------");
                next();
                return;
            }else{
                console.log("4----------");
                flag=true
                next({ path: '/home' + res.data.sort, query: { traceCode: to.query.traceCode } });
            }
          
        }).catch(err => {
            console.log(err);
            console.log("2----------");
            next();
        });
    }else if (['home6', 'home7', 'home8'].includes(to.name)&&!flag) {
        console.log("1----------");
        // 缓存，10秒内，不需要再重复调用
        // next()
        post(`/api/public/v2/manufacture/tracing/${to.query.traceCode}/findUsedTraceTemplatesByProductId`, {}, false).then((res) => {
            
            if (from.name === 'home' + res.data.sort) {
                console.log("8----------");
                next();
                return;
            }else{
                console.log("9----------");
                flag=true
                next({ path: '/home' + res.data.sort, query: { traceCode: to.query.traceCode } });
            }
          
        }).catch(err => {
            console.log(err);
            console.log("6----------");
            next();
        });
    }else{
        console.log("5----------");
        next()
    }
    NProgress.done();


})

router.afterEach(() => {
    NProgress.done() // finish progress bar
})